
export const tableKeys = {
	data() {
		return {
			cols: [
				{
					label: "Logo",
					key: "logo_url",
					sortable:true,
					width: 50
				},
				{
					label: "Name",
					key: "name",
					sortable:true,
					width: 300
				},
				{
					label: "Subdomain",
					key: "subdomain",
					sortable:true,
					width: 300
				},
				{
					label: "",
					key: "actions",
					sortable:true,
					width: 300
				}
			],
			filters:{
				search:{
					name: '',
					subdomain: ''
				},
				columns:[
					'logo_url',
					'name',
					'subdomain',
					'status'
				],
				sort:{
					field:'name',
					dir: "DESC"
				},
				page: 1,
				limit:25,
				offset:0
			},
		}
	}
};