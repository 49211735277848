<template>

        <div class="section-content">
            <div class="primary-section-content">
                <div class="main-section-heading">
                    <h1 class="main-section-header">Companies</h1>
                    <button @click="showAddCompany = true" class="w-button secondary-btn">
                        Add Company
                    </button>
                    <div class="title-section-settings">

                        <pagination
                                v-model="filters.page"
                                :limit="filters.limit"
                                :offset="filters.offset"
                                :result_count="result_count"
                                @input="buildQuery"
                        ></pagination>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <button class="w-button btn-sm secondary-btn" @click="showAside = !showAside">
                        <span v-show="showAside">
                            <span class="icon" ></span>&nbsp; Hide Filters
                        </span>

                        <span v-show="!showAside">
                            Show Filters  &nbsp;<span class="icon" v-show="!showAside"></span>
                        </span>


                        </button>

                    </div>
                </div>

                <div class="content-view">

                    <full-table
                            rowClass="clickable"
                            @rowClicked="viewCompany"
                            @filter="buildQuery"
                            :filters="filters"
                            :tableData="companies"
                            :cols="cols"
                            mobileTriggerText="View Details"
                    >
                        <template slot="logo_url_data" slot-scope="data">
                            <img style="max-height: 20px;"  :src="data.value" />
                        </template>

                    </full-table>

                </div>
            </div>

            <div class="aside" :class="{hide: !showAside}">
                <div class="aside-header">
                    <div>
                        <h2 class="heading-3">Filter Companies</h2>
                        <button class="w-button close-btn" @click="showAside = false">
                            <span class="icon"></span>
                        </button>
                    </div>
                </div>
                <div class="aside-summary-section">
                    <!--<div class="aside-summary-row vertical" >-->
                        <!--<label for="name" class="aside-summary-label">-->
                            <!--Search By Name-->
                        <!--</label>-->
                        <!--<div class="form-block w-form">-->
                            <!--<input-->
                                    <!--type="text"-->
                                    <!--class="w-input"-->
                                    <!--name="name"-->
                                    <!--v-model.lazy="filters.search.name"-->
                                    <!--data-name="name"-->
                                    <!--placeholder=""-->
                                    <!--id="name"-->
                                    <!--aria-label="Name"-->
                            <!--&gt;-->
                        <!--</div>-->
                    <!--</div>-->



                    <!--<div class="aside-summary-row vertical" >-->
                        <!--<label for="name" class="aside-summary-label">-->
                            <!--Search By Subdomain-->
                        <!--</label>-->
                        <!--<div class="form-block w-form">-->
                            <!--<input-->
                                <!--type="text"-->
                                <!--class="w-input"-->
                                <!--name="subdomain"-->
                                <!--v-model.lazy="filters.search.subdomain"-->
                                <!--data-name="subdomain"-->
                                <!--placeholder=""-->
                                <!--id="subdomain"-->
                                <!--aria-label="Subdomain"-->
                            <!--&gt;-->
                        <!--</div>-->
                    <!--</div>-->
                </div>
            </div>

            <modal v-if="showAddCompany" size="lg" @close="closeWindow">
                <h3 slot="header">Add Company</h3>
                <div slot="body">
                    <add-account @saved="saved" @close="closeWindow"></add-account>
                </div>
            </modal>


            <!--<slide-out-window :class="{hide: !showContact}"  @close="hideContactWindow">-->
                <!--<contact-view-->
                        <!--v-if="application_id"-->
                        <!--:contact_id="contact_id"-->
                        <!--:application_id="application_id"-->
                        <!--show="overview"-->
                        <!--slot="body"-->
                        <!--@close="hideContactWindow"-->
                <!--&gt;-->
                <!--</contact-view>-->

            <!--</slide-out-window>-->
        </div>

    </template>




    <!--<div id="content" class="row primary-content">-->
        <!--<div class="section">-->
            <!--<div class="container-fluid">-->
                <!--<status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->
                <!--<div class="row row-head lined">-->
                    <!--<div class="col-xs-12">-->
                        <!--<h5>Accounts</h5>-->
                        <!--<p>View accounts</p>-->
                    <!--</div>-->
                <!--</div>-->

                <!--<div class="row display-head">-->
                    <!--<div class="col-xs-4">Name</div>-->
                    <!--<div class="col-xs-4">Logo</div>-->
                    <!--<div class="col-xs-4">Subdomain</div>-->
                <!--</div>-->
                <!--<div class="row lined" v-for="c in companies" :key="c.id">-->
                    <!--<div class="col-xs-4 link">-->
                        <!--{{ c.name }}-->
                    <!--</div>-->
                    <!--<div class="col-xs-4 link">-->
                        <!--{{ c.logo }}-->
                    <!--</div>-->
                    <!--<div class="col-xs-4 link">-->
                        <!--{{ c.subdomain }}-->
                    <!--</div>-->


                    <!--<div class="actions-holder">-->
                        <!--<div class="dropdown ">-->
                            <!--<a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" href="#">-->
                                <!--<i class="fa fa-ellipsis-v" ></i>-->
                            <!--</a>-->

                            <!--<ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">-->
                                <!--<li>-->
                                    <!--<a @click.prevent="editCompany(a)">-->
                                        <!--<i class="fa fa-pencil"></i>-->
                                        <!--&nbsp;&nbsp;Edit Company-->
                                    <!--</a>-->
                                <!--</li>-->
                                <!--<li>-->
                                    <!--<a @click.prevent="deleteCompany(a)">-->
                                        <!--<i class="fa fa-remove"></i>-->
                                        <!--&nbsp;&nbsp;Delete Company-->
                                    <!--</a>-->
                                <!--</li>-->
                            <!--</ul>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->


                <!--<div class="row">-->
                    <!--<div class="col-xs-12">-->
                        <!--<button class="btn btn-dark" @click="showAddCompany = true;"><i class="fa fa-plus"></i>&nbsp;Create New Account</button>-->

                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->


        <!--<modal v-if="showAddCompany" size="md" allow-click-away="true" @close="closeWindow">-->
            <!--<h3 slot="header">Add Company</h3>-->
            <!--<div slot="body">-->
                <!--<add-account @saved="saved" @close="closeWindow"></add-account>-->
            <!--</div>-->
        <!--</modal>-->

    <!--</div>-->
</template>

<script type="text/babel">
    import api from '../../assets/api.js';
    import Modal from '../assets/Modal.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import DropdownConfirmation from '../assets/DropdownConfirmation.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import AddAccount from './AddAccount.vue';
    import SortArrow from '../assets/SortArrow.vue';
    import moment from 'moment';
    import Pagination from '../assets/Pagination.vue';
    import { tableFilters } from '../../mixins/urlParser.js';
    import FullTable from '../assets/FullTable.vue';
    import { tableKeys } from '../../mixins/tableColumns/companies.js';

    export default {
        name: "ListAccounts",
        data() {
            return {
                companies:[],
                selected:{},
                showAddCompany:false,
                showAside: false
            }
        },
        components:{
            Modal,
            Status,
            Dropdown,
            DropdownConfirmation,
            AddAccount,
            SortArrow,
            Pagination,
            FullTable
        },
        created (){
            this.buildQuery();

        },
        computed:{},
        filters: {},
        methods:{
            closeWindow(){
                this.showEdit = false;
                this.showReject = false;
                this.showView = false;
                this.showAddCompany = false;
                this.selected = {};
            },
            saved(){
                this.closeWindow();
                this.search();
            },
            search(){

                api.get(this, api.COMPANIES).then(r => {
                    this.showEdit = false;
                    this.companies =  r.companies;
                });
            },
            viewCompany(){

            }

        },
        mixins:[tableFilters, tableKeys]
    }



</script>

<style >
    .link{
        cursor: pointer;
    }
    body .application-actions .btn{
        margin-bottom: 10px;
    }
    body .application-actions .dropdown-menu{
        min-width: 200px;
    }

    .btn.btn-lg {
        padding: 15px;
    }
    .modal-footer.application-actions .btn+.btn {
        margin-bottom: 10px;
        margin-left: 0px;
    }
</style>
