<template>
    <div class="container-fluid">

        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>


        <div class="form-section">
            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Company Name</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input" :class="{'has-error': errors.has('name') }">
                        <input
                            v-validate="'required'"
                            class="w-input input"
                            type="text"
                            v-model="company.name"
                            name="name"
                            id="name"
                        />
                    </div>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Subdomain</label>
                <div class="form-input-container">
                    <div class="horizontal-form-input">
                        http://
                    </div>
                    <div class="horizontal-form-input" :class="{'has-error': errors.has('subdomain') }">
                        <input
                                v-validate="'required'"
                                class="w-input input"
                                type="text"
                                v-model="company.subdomain"
                                name="subdomain"
                                id="subdomain"
                        />
                    </div>
                    <div class="horizontal-form-input">
                        .leasecaptain.com
                    </div>
                </div>
            </div>

            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Primary Contact</label>
                <div class="form-input-container">
                    <div  class="horizontal-form-input" :class="{'has-error': errors.has('firstname') }">
                        <input
                                v-validate="'required'"
                                class="w-input input"
                                type="text"
                                v-model="company.firstname"
                                name="firstname"
                                id="firstname"
                                placeholder="first"
                        />
                    </div>

                    <div class="horizontal-form-input" :class="{'has-error': errors.has('lastname') }">
                        <input
                                v-validate="'required'"
                                class="w-input input"
                                type="text"
                                v-model="company.lastname"
                                name="lastname"
                                id="lastname"
                                placeholder="last"
                        />
                    </div>

                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Primary Contact Email</label>
                <div class="form-input-container">
                    <div  class="horizontal-form-input" :class="{'has-error': errors.has('email') }">
                        <input
                                v-validate="'required'"
                                class="w-input input"
                                type="text"
                                v-model="company.email"
                                name="email"
                                id="email"
                        />
                    </div>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Primary Contact Phone</label>
                <div class="form-input-container">
                    <div  class="horizontal-form-input" :class="{'has-error': errors.has('phone') }">
                        <input
                            class="w-input input"
                            type="text"
                            name="phone"
                            id="phone"
                            v-model="company.phone"
                        />
                    </div>
                </div>
            </div>


            <div class="horizontal-form-row">
                <label class="horizontal-form-label" for="name">Company Logo</label>
                <div class="form-input-container">
                    <div  class="horizontal-form-input" :class="{'has-error': errors.has('logo') }">
                        <input
                                class="w-input input"
                                type="file"
                                name="logo"
                                id="logo"
                                @change="setLogo"
                        />
                    </div>
                </div>
            </div>


        </div>

        <div class="modal-footer">
            <a class="close-link" @click="$emit('close')">Cancel</a>
            <button :disabled="isLoading($options.name)" class="w-button primary-btn" @click="create">Save</button>
             <span v-show="isLoading($options.name)" >
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>

        </div>

    </div>


</template>


<script type="text/babel">
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import DropdownConfirmation from '../assets/DropdownConfirmation.vue';
    import Dropdown from '../assets/Dropdown.vue';

    export default {
        name: "ListAccounts",
        data() {
            return {
                company: {
                    name: '',
                    firstname: '',
                    lastname: '',
                    email : '',
                    phone: '',
                    subdomain: '',
                    logo: '',
                    active: 1
                },
                logoFile: {}
            }
        },
        components:{
            Status,
            Dropdown,
            DropdownConfirmation,
            Loader

        },
        created (){

        },
        computed:{
            leaseDates(){
                return this.leases.map(function(lease){ return lease.start_date + ' - ' + lease.end_date});
            }
        },
        filters: {
            formatTime(val){

                return moment(val).format('M/DD/YYYY hh:mm a')
            }
        },
        methods:{
            closeWindow(){
                this.showEdit = false;
                this.showReject = false;
                this.showView = false;
                this.showAdd = false;
                this.selected = {};
            },

            create(){

                this.validate(this).then(status => {
                    if(!status) return;
                    api.post(this, api.COMPANIES, this.company).then(r => {
                        this.$emit('saved');
                    });
                })


            },

            setLogo(file){
                
            }

        }
    }



</script>